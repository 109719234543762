<template>
  <div>
    <!-- BEGIN breadcrumb -->
    <ol class="breadcrumb float-xl-end">
      <li class="breadcrumb-item"><a href="/app/dashboard">Home</a></li>
      <li class="breadcrumb-item active"><a href="javascript:;">Usuário</a></li>
    </ol>
    <!-- END breadcrumb -->
    <!-- BEGIN page-header -->
    <h1 class="page-header">Listagem dos usuários</h1>
    <!-- END page-header -->
    <panel :noButton="true">
      <div
        slot="header"
        class="w-100 d-flex align-items-center justify-content-between"
      >
        <span>Listagem dos usuários</span>
        <button
          v-if="checkFuncionalidade(16)"
          @click="novoUsuario"
          class="btn btn-success"
        >
          Novo usuário
        </button>
      </div>
      <div class="col-12 mb-2">
        <label class="form-label">Pesquisar:</label>
        <input class="form-control" type="text" v-model="filter" />
      </div>
      <b-table
        sticky-header
        :filter="filter"
        :fields="fields"
        :busy="isBusy"
        :dark="true"
        :items="items"
      >
        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Carregando...</strong>
          </div>
        </template>

        <template #cell(usuario)="data">
          {{ data.item.id }} - {{ data.item.nome }}
        </template>
        <template #cell(email)="data">
          {{ data.item.email }}
        </template>
        <template #cell(perfil)="data">
          {{ data.item.perfil.id }}-{{ data.item.perfil.descricao }}
        </template>
        <template #cell(acao)="data">
          <button
            v-if="checkFuncionalidade(17)"
            @click="detalhesUsuario(data.item.id)"
            class="btn btn-info"
          >
            Detalhes
          </button>
        </template>
      </b-table>
    </panel>
    <modal-cliente :loadItens="loadItens" />
  </div>
</template>

<script>
import axios from 'axios'
import CheckFunc from '../../../mixins/CheckFunc'
export default {
  mixins: [CheckFunc],
  data() {
    return {
      isLoad: true,
      saving: false,
      isBusy: false,
      filter: '',
      fields: [
        { key: 'usuario', label: 'Usuário' },
        { key: 'email', label: 'E-mail' },
        { key: 'perfil', label: 'Perfil' },
        { key: 'acao', label: 'Ação' }
      ],
      items: []
    }
  },
  methods: {
    detalhesUsuario(id) {
      this.$router.push({ name: 'usuario.formulario', params: { id: id } })
    },
    novoUsuario() {
      this.$router.push({ name: 'usuario.novo' })
    },
    async loadItens() {
      this.isBusy = true
      await axios
        .get('usuario?page=1&per_page=100000')
        .then(res => {
          return res.data.dados
        })
        .then(res => {
          this.items = res.data
          this.isBusy = false
        })
    }
  },
  computed: {},
  mounted() {
    this.loadItens()
  }
}
</script>
